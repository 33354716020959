import { Renderer, Geometry, Program, Mesh } from 'ogl';

import vertex from '../shaders/vertex.glsl'
import fragment from '../shaders/fragment.glsl'

class App {
    constructor() {
        this.cursor = {}
        this.sizes = {}

        this.cursor.x = 0
        this.cursor.y = 0

        this.createRenderer()
        this.createMesh()
        this.addListeners()
        this.getScreenSize()
        this.onResize()

        requestAnimationFrame(this.update.bind(this))
    }

    createRenderer() {
        this.renderer = new Renderer({
            width: window.innerWidth,
            height: window.innerHeight
        })

        this.gl = this.renderer.gl
        document.body.appendChild(this.gl.canvas)
    }

    createMesh() {
        this.geometry = new Geometry( this.gl, {
            position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
            uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) },
        })

        this.program = new Program( this.gl, {
            vertex,
            fragment,
            uniforms: {
                uTime: { value: 0 },
                uCursorX: { value: 0 },
                uCursorY: { value: 0 }
            },
        })
        
        this.mesh = new Mesh(this.gl, {
            geometry: this.geometry, 
            program: this.program 
        })
    }
    
    update(time) {
        this.program.uniforms.uTime.value = time * 0.001
        
        this.renderer.render({ scene: this.mesh })
        requestAnimationFrame(this.update.bind(this))
    }

    getScreenSize () {
        this.sizes.width = window.innerWidth
        this.sizes.height = window.innerHeight
    }

    onResize() {
        this.getScreenSize()
        this.renderer.setSize(this.sizes.width,this.sizes.height)
    }   

    addListeners() {
        window.addEventListener("resize", this.onResize.bind(this))

        window.addEventListener('DOMContentLoaded', () => {
            document.querySelector('.preloader').classList.add('--hidden')
        })
    }
}

new App();